import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { ShowProcessedImage } from "../../common/ggfx-client/module/components/show-image"
import imageConfig from "../../../static/images/config.json"
import NoImage from "../../images/manager-img.png"
import moment from "moment"
import "./PropertySlider.scss"

const PropertySlider = props => {
  let propCount = props.title?.slice(0, 1)

  let title = isNaN(propCount) && props.title.slice(0,1).toUpperCase() + props.title.slice(1);
  title ||= props.title
  let bedlist = [1,2,3,4,5,6,7,8,9,10]

  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        globalModule {
          Phone
          Whatsapp
        }
      }
    }
  `);
  const global = data.glstrapi.globalModule;

  return (
    <div className={`property-card ${props.offPlan ? "off-plan-card" : ""}`}>
      {props.offPlan ? (
        <>
          <Link to={props.propertyDetailsLink}>
            <div className="property-img">
              {props.propertyImages && props.propertyImages.length > 0 ? (
                <ShowProcessedImage
                  images={props.propertyImages && props.propertyImages[0]}
                  attr={{
                    className: "mb-0 img-fluid",
                    alt: props.location,
                  }}
                  transforms={imageConfig.offplan.searchResults.sizes}
                />
              ) : props.thumb ? (
                <img src={props.thumb} alt="card-img" />
              ) : (
                <img src={NoImage} alt="" />
              )}
            </div>
          </Link>

          <div className="property-content">
            <Link to={props.propertyDetailsLink}>
              <h4>{props?.address?.building_name}</h4>
              <h3>{props?.area}</h3>
            </Link>
            {props.price ? (
              <>
                <p className="price"><b>Prices from :</b> {`AED ${props.price} `}
                {/* ${props.max_price ? `- AED ${props.max_price}` : ""} */}
                </p>
                <div className="_divider"></div>
              </>
            ) : (
              ""
            )}
            {props.bedroom ? <><p>
            {bedlist?.map((item, i) => {
                                    return(
                                        <>
                                        {props.bedroom <= item && props.max_bedrooms >= item ?
                                    <>{props.max_bedrooms === item ? `&  ${item} ` : `${item} `}</>
                                    : null}
                                        </>
                                    )
                                })}
                                 Bedroom Apartments</p>
                                 <div className="_divider"></div></> : null}
            {props?.completion_date && (
              <>
                <div className="_divider"></div>
                <p>
                  Completion Date:{" "}
                  {moment(props?.completion_date).format("MMMM YYYY")}
                </p>
                <div className="_divider"></div>
              </>
            )}

            <div className="contact_section">
                <a className="phone" href={`tel:${global.Phone}`}>
                    <i className="icon-phone"></i> Call
                </a>
                <a href={`https://wa.me/${global.Whatsapp}`} target="_blank" className="whatsapp">
                    <i className="icon-whatsapp"></i> Whatsapp
                </a>
            </div>

            <div className="view_section">
              <Link to={props.propertyDetailsLink} className="view-details">
                View Details
              </Link>
            </div>
          </div>
        </>
      ):
      props.holiday ?       (
        <Link to={props.propertyDetailsLink}>
          <div className="property-img">
            {props.propertyImages && props.propertyImages.length > 0 ? (
              <ShowProcessedImage
                images={props.propertyImages && props.propertyImages[0]}
                attr={{
                  className: "mb-0 img-fluid",
                  alt: props.location,
                }}
                transforms={imageConfig.property.searchResults.sizes}
              />
            ) : props.thumb ? (
              <img src={props.thumb} alt="card-img" />
            ) : (
              <img src={NoImage} alt="" />
            )}
          </div>
          <div className="property-content">
            {props.price ? (
              <div
                className={`head-wrap ${
                  props.holiday ? "holiday-let-head" : ""
                }`}
              >
                <h4 className="item-ares">
                  {props.currency ? props.currency : "AED "} {props.price}
                </h4>
                {props.holiday && <span class="duration">per night</span>}
              </div>
            ) : (
              ""
            )}
            <h3>{props.location}</h3>
            <p>{props?.bedroomsCount} beds / {props?.bathroomsCount} baths</p>
            {props.UnderOffer && <span className="offer-tag">Under Offer</span>}
          </div>
        </Link>
      ) :
      (
        <Link to={props.propertyDetailsLink}>
          <div className="property-img">
            {props.propertyImages && props.propertyImages.length > 0 ? (
              <ShowProcessedImage
                images={props.propertyImages && props.propertyImages[0]}
                attr={{
                  className: "mb-0 img-fluid",
                  alt: props.location,
                }}
                transforms={imageConfig.property.searchResults.sizes}
              />
            ) : props.thumb ? (
              <img src={props.thumb} alt="card-img" />
            ) : (
              <img src={NoImage} alt="" />
            )}
          </div>
          <div className="property-content">
            {props.price ? (
              <div
                className={`head-wrap ${
                  props.holiday ? "holiday-let-head" : ""
                }`}
              >
                <h4 className="item-ares">
                  {props.currency ? props.currency : "AED "} {props.price}
                </h4>
                {props.holiday && <span class="duration">per night</span>}
              </div>
            ) : (
              ""
            )}
            <h3>{props.location}</h3>
            {props?.building?.length > 0 && props?.building[0] !== "Land" &&
            <p>{title}</p>
            }
            {props.UnderOffer && <span className="offer-tag">Under Offer</span>}
          </div>
        </Link>
      )  }
    </div>
  )
}

export default PropertySlider
